<template>
    <div class="goods-page">
        <div class="goods-information" v-if="detailData.order">
            <div>
                <p>发货单号:</p>
                <p>{{ detailData.order.order_sn }}</p>
            </div>
            <div>
                <p>发货时间:</p>
                <p>{{ detailData.order.delivery_at }}</p>
            </div>
            <div>
                <p>数　量:</p>
                <p>x {{ detailData.order.number }}</p>
            </div>
            <div>
                <p>备　注:</p>
                <p>{{ detailData.order.remark }}</p>
            </div>
        </div>
        <div class="goods-checklist">
            <van-collapse v-model="collapseActive" accordion>
                <van-collapse-item
                    v-for="(item, index) in dataList"
                    class="checklist-item"
                    :name="index"
                    :title="item.production"
                >
                    <p>资产序列号：{{ item.assets }}</p>
                    <p>终端序列号:{{ item.equipment }}</p>
                </van-collapse-item>
            </van-collapse>
        </div>
        <div class="goods-installation-picture">
            <div class="image-caption-group">
                <div class="decorative-thread"></div>
                <p>现场图片</p>
            </div>
            <div class="image-display">
                <van-grid>
                    <van-grid-item v-for="(item, index) in detailData.images">
                        <van-image :src="item" />
                    </van-grid-item>
                </van-grid>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { GET_GOODS_INSTALL_DETAIL, GET_GOODS_RECEIVE_POLE_DETAIL } from "@/api/goods";
import usePublic from "@/hooks/usePublic";

let { $route, $router, $store } = usePublic();

onMounted(() => {
    getDetail();
});

let collapseActive = ref("null");

// 获取详情
let detailData = ref({});
let dataList = ref([]);

let getDetail = () => {
    GET_GOODS_INSTALL_DETAIL({ id: $route.params.id }).then((res) => {
        detailData.value = res.data.data;
        console.log(res.data.data);
        GET_GOODS_RECEIVE_POLE_DETAIL({ id: detailData.value.order.id }).then((cres) => {
            console.log(cres.data.data);
            dataList.value = cres.data.data;
        });
    });
};
</script>

<style lang="scss" scoped>
.goods-page {
    min-height: 100vh;
    padding-bottom: 60px;
    background: #f0f0f0;

    .goods-information {
        min-height: 20vh;
        background: #fff;
        font-size: 12px;
        display: flex;
        flex-direction: column;

        div {
            margin-top: 20px;
            padding: 0 15px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            color: #000;

            p:nth-child(2) {
                color: #999999;
            }
        }

        div:nth-child(4) {
            flex-direction: column;
            margin-bottom: 20px;

            p:nth-child(2) {
                padding-top: 10px;
            }
        }

        div:nth-child(1) {
            p:nth-child(2) {
                color: #df5f0b;
            }
        }
    }

    .goods-checklist {
        min-height: 10vh;
        margin-top: 10px;
        background: #fff;
        padding: 20px 0px;

        .checklist-item {
            p {
                margin: 10px 0;
            }
        }
    }

    .goods-installation-picture {
        margin-top: 10px;

        .image-caption-group {
            margin-bottom: 10px;
            padding: 0 20px;
            font-size: 13px;
            display: flex;
            flex-direction: row;
            align-items: center;

            .decorative-thread {
                height: 20px;
                width: 5px;
                background: #ff8d1a;
                margin-right: 10px;
            }

            p {
                font-weight: bold;
            }
        }

        .image-display {
            min-height: 100px;
            background: #fff;
            padding: 20px 15px;
        }
    }
}
</style>
